import { encodeBase64, decodeBase64 } from './base64';

export function updateURL(content: string): void {
  const base64Content = encodeBase64(content);
  const newUrl = `${window.location.pathname}?diagram=${base64Content}`;
  window.history.replaceState({}, '', newUrl);
}

export function getContentFromURL(): string {
  const params = new URLSearchParams(window.location.search);
  const base64Content = params.get('diagram');
  
  if (!base64Content) return '';
  return decodeBase64(base64Content);
}