import mermaid from 'mermaid';

const config = {
  startOnLoad: true,
  theme: 'dark',
  securityLevel: 'loose',
  logLevel: 'error'
};

let initialized = false;

export async function initializeMermaid(): Promise<void> {
  if (initialized) return;
  
  try {
    await mermaid.initialize(config);
    initialized = true;
  } catch (error) {
    console.error('Failed to initialize Mermaid:', error);
    throw error;
  }
}

export async function renderDiagram(content: string, elementId: string): Promise<void> {
  if (!initialized) {
    await initializeMermaid();
  }

  try {
    const element = document.getElementById(elementId);
    if (!element) return;
    
    // Clear previous content
    element.innerHTML = '';
    
    // Create pre element with mermaid class
    const pre = document.createElement('pre');
    pre.className = 'mermaid';
    pre.textContent = content;
    element.appendChild(pre);
    
    // Render diagram
    await mermaid.run({
      nodes: [pre]
    });
    
  } catch (error) {
    console.error('Failed to render diagram:', error);
    showError(elementId, error);
  }
}

function showError(elementId: string, error: unknown): void {
  const element = document.getElementById(elementId);
  if (!element) return;
  
  const message = error instanceof Error ? error.message : 'Failed to render diagram. Please check your syntax.';
  element.innerHTML = `<div class="error-message">${message}</div>`;
}