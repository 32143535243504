import { EditorView, basicSetup } from "codemirror";
import { EditorState } from "@codemirror/state";
import { oneDark } from "@codemirror/theme-one-dark";
import type { ViewUpdate } from "@codemirror/view";

export interface EditorConfig {
  initialValue: string;
  onChange: (content: string) => void;
  parent: HTMLElement | null;
}

export class CodeEditor {
  private view: EditorView | null = null;

  constructor(private config: EditorConfig) {
    this.initialize();
  }

  private initialize(): void {
    if (!this.config.parent) return;

    const state = EditorState.create({
      doc: this.config.initialValue,
      extensions: [
        basicSetup,
        oneDark,
        EditorView.updateListener.of((update: ViewUpdate) => {
          if (update.docChanged) {
            this.config.onChange(update.state.doc.toString());
          }
        }),
      ],
    });

    this.view = new EditorView({
      state,
      parent: this.config.parent,
    });
  }

  public updateContent(content: string): void {
    if (!this.view) return;

    this.view.dispatch({
      changes: {
        from: 0,
        to: this.view.state.doc.length,
        insert: content
      }
    });
  }

  public destroy(): void {
    this.view?.destroy();
  }
}