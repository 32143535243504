// Utility functions for base64 encoding/decoding
export function encodeBase64(str: string): string {
  return btoa(encodeURIComponent(str));
}

export function decodeBase64(base64: string): string {
  try {
    return decodeURIComponent(atob(base64));
  } catch (e) {
    console.error('Failed to decode base64 string:', e);
    return '';
  }
}